import React from "react";

export default function Footer() {
  return (
    <footer>
      <small>
        <span>&copy;</span>
        TomHoangDev. All rights reserved.
      </small>
    </footer>
  );
}
